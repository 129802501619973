import { createStore } from 'vuex';
import { auth } from "./auth.store";
import axios from "axios";
import _ from "lodash";

import materialModule from './modules/MaterialModule'
import logbookModule from './modules/LogbookModule'
import remarkModule from './modules/RemarkModule'
import historyModule from './modules/HistoryModule'
import dashboardModule from './modules/DashboardModule'

export const store = createStore({
    state: { isLoading: true, labels: {}},
    getters: {
        trans: (state) => {
            return (string) => {
                return  _.upperFirst(_.get( state.labels, string));
            }
        }
    },
    mutations: {
        setLoading(state,value){
            state.isLoading = value;
        },
        getLabels(state) {
            return new Promise((resolve) => {
                axios.post(auth.state.base_url+'/api/getlabels')
                    .then((response) => {
                        state.labels = response.data;
                        resolve('ok');
                    }, () => {
                        auth.actions.signOut();
                    });
            });
        }
    },
    actions: {

    },
    modules: {
        auth,
        historyModule,
        dashboardModule,
        materialModule,
        logbookModule,
        remarkModule
    },
})
