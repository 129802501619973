import axios from "axios";
const actions = {
    submitRegistration({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/history/create', data.payload).then((response) => {
                commit('HISTORY_STARTED', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
    stopRegistration({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/history/stop', data.payload).then((response) => {
                commit('HISTORY_STOPPED', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
    historyPause({commit},data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/history/pause', data.payload).then((response) => {
                commit('HISTORY_PAUSED', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
    getCurrentTime({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/history/currenttime').then((response) => {
                commit('GET_CURRENT_TIME', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
}

export default actions;
