import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
    current_time: '',
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
