import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
    absence_total: 0,
    recup_total: 0,
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
