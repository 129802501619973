import axios from "axios";
const actions = {
    getMaterials({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/workingmaterials').then((response) => {
                commit('GET_MATERIALS', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
}

export default actions;
