<template>
  <div class="preloader">
      <ion-spinner :color="color" name="circular"></ion-spinner>
  </div>
</template>

<script>


import {IonSpinner} from "@ionic/vue";

export default {
  name: "MyPreloader",
  components: {
   IonSpinner
  },
  props: {
    color: {
      type: String,
      default: 'epacco'
    }
  }
}
</script>

<style scoped>
@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: .6
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0
  }
}

@keyframes swing {
  50% {
    transform: rotate(-3deg)
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: .6
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0
  }
}

.preloader{
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
}

svg.tea #teabag {
  transform-origin: top center;
  transform: rotate(
      3deg);
  animation: swing 2s infinite !important;
}

svg.tea #steamL {
  stroke-dasharray: 13;
  stroke-dashoffset: 13;
  animation: steamLarge 2s infinite;
}

svg.tea #steamR {
  stroke-dasharray: 9;
  stroke-dashoffset: 9;
  animation: steamSmall 2s infinite;
}

ion-spinner {
  width: 75px;
  height: 75px;
}
</style>
