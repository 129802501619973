<template>
  <ion-menu side="start" menu-id="app-menu" content-id="main-content">
      <ion-header>
          <ion-toolbar color="epacco">
              <ion-title>Menu</ion-title>
          </ion-toolbar>
      </ion-header>
      <ion-content>
          <ion-list>
              <ion-item button @click="menuNavigation('/dashboard')">
                  <ion-icon :icon="speedometerOutline" slot="start"></ion-icon>
                  <ion-label>{{trans('menu.dashboard')}}</ion-label>
              </ion-item>
              <ion-item button @click="menuNavigation('/planning')">
                  <ion-icon :icon="calendarOutline" slot="start"></ion-icon>
                  <ion-label>{{trans('menu.planning')}}</ion-label>
              </ion-item>
              <ion-item v-if="logbook_customer_rights" button @click="menuNavigation('/logbookcustomer')">
                <ion-icon :icon="bookOutline" slot="start"></ion-icon>
                <ion-label>{{trans('menu.logbook_customer')}}</ion-label>
              </ion-item>
            <ion-item v-if="logbook_rights" button @click="menuNavigation('/logbook')">
              <ion-icon :icon="bookOutline" slot="start"></ion-icon>
              <ion-label>{{trans('menu.logbook')}}</ion-label>
            </ion-item>
              <ion-item button @click="menuNavigation('/absencerequest')">
                  <ion-icon :icon="airplaneOutline" slot="start"></ion-icon>
                  <ion-label>{{trans('menu.absencerequest')}}</ion-label>
              </ion-item>
              <ion-item button @click="menuNavigation('/ordermaterial')">
                  <ion-icon :icon="pricetagsOutline" slot="start"></ion-icon>
                  <ion-label>{{trans('menu.order_material')}}</ion-label>
              </ion-item>
              <ion-item v-if="remark_rights" button @click="menuNavigation('/remarks')">
                <ion-icon :icon="balloonOutline" slot="start"></ion-icon>
                <ion-label>{{trans('menu.remarks')}}</ion-label>
              </ion-item>
              <ion-item button @click="menuNavigation('/profile')">
                  <ion-icon :icon="personOutline" slot="start"></ion-icon>
                  <ion-label>{{trans('menu.profile')}}</ion-label>
              </ion-item>
              <ion-item @click="doSignOut">
                  <ion-icon :icon="logOutOutline" slot="start"></ion-icon>
                  <ion-label>{{trans('menu.uitloggen')}}</ion-label>
              </ion-item>
          </ion-list>
      </ion-content>
  </ion-menu>
</template>

<script>
import { IonItem, IonLabel, IonList, IonTitle, IonHeader, IonToolbar, IonContent, IonMenu, menuController, IonIcon} from "@ionic/vue";
import { logOut, calendarOutline, logOutOutline, airplaneOutline, personOutline, bookOutline, speedometerOutline, pricetagsOutline, balloonOutline} from 'ionicons/icons';
import {mapActions, mapGetters, mapState} from "vuex";
import {useRouter} from 'vue-router';
import _ from "lodash";

export default {
  name: "MainMenu",
  components:
      {
        IonTitle,
        IonHeader,
        IonToolbar,
        IonContent,
        IonLabel,
        IonList,
        IonItem,
        IonMenu,
        IonIcon
      },
  props: {
  },
  setup() {
    const router = useRouter();
    return {
      logOut,
      calendarOutline,
      airplaneOutline,
      logOutOutline,
      personOutline,
      speedometerOutline,
      pricetagsOutline,
      bookOutline,
      balloonOutline,
      router,
    };
  },
  data() {
    return {
      data1: null
    }
  },
  computed:{
    ...mapGetters(["trans"]),
    ...mapState('auth', ['icalLink', 'username', 'loggedIn', 'rights']),
    logbook_customer_rights() {
      return _.get(this, 'rights.logbook_customer', false);
    },
    logbook_rights() {
      return _.get(this, 'rights.logbook', false);
    },
    remark_rights() {
      return _.get(this, 'rights.mobile_user_remarks', false);
    }
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    doSignOut(){
      this.signOut().then(() => {
        menuController.close("app-menu");
        this.router.push("/");
        window.location.reload();
      });
    },
    menuNavigation(url){
        menuController.close("app-menu");
        this.$router.push(url);
    }

  }
}
</script>

<style scoped>
.overscroll h2{
    margin-left: 15px;
    font-size: 22px;
    font-weight: 400;
}
</style>
