import axios from "axios";
const actions = {
    getCustomers({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/customers', data.payload).then((response) => {
                commit('GET_CUSTOMERS', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
    getLogbookReceivers({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/logbook-receivers', data.payload).then((response) => {
                commit('GET_LOGBOOK_RECEIVERS', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
    getLogbookCustomerReceivers({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/logbook-customer-receivers', data.payload).then((response) => {
                commit('GET_LOGBOOK_CUSTOMER_RECEIVERS', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },

    saveLogbook({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/save-logbook', data.payload).then((response) => {
                commit('LOGBOOOK_SAVED', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
}

export default actions;
