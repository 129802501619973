import axios from "axios";
const actions = {
    getUsers({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/users', data.payload).then((response) => {
                commit('GET_USERS', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },

    getTypes({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(data.base_url + '/api/user-remark-types').then((response) => {
                commit('GET_TYPES', response.data);
                resolve();
            }, (error) => {
                reject(error.response);
            });
        });
    },
}

export default actions;
