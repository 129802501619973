import axios from "axios";
import {Storage} from '@ionic/storage';

const store = new Storage();
store.create();

const state = {
    loggedIn: false,
    icalLink: '',
    username: '',
    rights: {},
    base_url: 'https://planning.epacco.be'
    //base_url: 'https://epaccoplanning.test'
};

const getters = {
    loggedIn: (state) => {
        return state.loggedIn;
    }
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    async signIn(context, signInData) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            //state.loggedIn = true;
            axios.post(state.base_url+"/api/login", signInData).then(res => {
                state.loggedIn = res.data.status;
                state.icalLink = res.data.icalLink;
                if (state.loggedIn) {
                    auth.actions.setToken(res.data.token);
                    auth.actions.setUsername(res.data.username);
                    auth.actions.setRights(res.data.rights);
                    window.location.reload();
                }
                document.querySelector('#wrongLogin').innerText = '';
                resolve(state.loggedIn);
                // eslint-disable-next-line no-unused-vars
            }).catch(res => {
                document.querySelector('#wrongLogin').innerText = 'Gegevens incorrect';
                reject();
            })
        });

    },

    async setToken(apiToken) {
        await store.set('apiToken', apiToken);
    },

    async getToken(){
        return await store.get('apiToken');
    },

    async setUsername(username) {
        await store.set('username', username);
    },

    async getUsername(){
        return await store.get('username');
    },

    async setRights(rights) {
        await store.set('rights', rights);
    },

    async getRights(){
        return await store.get('rights');
    },

    async signOut() {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            state.loggedIn = false;
            auth.actions.setToken(null);
            resolve();
        });
    },

    async checkLogin() {
        let username = await auth.actions.getUsername();
        let rights = await auth.actions.getRights();
        if (username) {
            auth.state.rights = rights;
        }
        return new Promise((resolve) => {
            axios.post(state.base_url + "/api/checkloggedin").then(res => {
                state.loggedIn = res.data.status;
                resolve();
            })
        });
    },

};

export const auth = {
    namespaced: true,
    state,
    getters,
    actions
};
