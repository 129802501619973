<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="epacco">
                <ion-buttons @click="openMenu()" slot="start">
                    <ion-icon :icon="menu" slot="start"></ion-icon>
                </ion-buttons>

                <ion-title>{{pageTitle}}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <slot></slot>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonHeader, IonContent, IonTitle, IonToolbar, IonButtons, IonIcon, menuController} from '@ionic/vue';
import { menu } from 'ionicons/icons';
import axios from "axios";
import {mapState} from "vuex";
export default {
    components:{
        IonPage,IonHeader,IonContent,IonTitle,IonToolbar,IonButtons, IonIcon
    },
    props:['pageTitle', 'pageIcon'],
    setup() {
        return {
        };
    },
    data() {
        return {
            menu
        };
    },
    computed: {
        ...mapState("auth", [ "base_url", "labels"]),
    },
    created() {

    },
    methods: {
        openMenu() {
            menuController.open("app-menu");
        },
        getLabels() {
            let _self = this;
            return new Promise((resolve, reject) => {
                axios.post(_self.base_url+'/api/getlabels')
                    .then((response) => {
                        _self.labels = response.data.labels;
                        resolve();
                    }, (error) => {
                        reject(error.response);
                    });
            });
        }
    },
}
</script>
