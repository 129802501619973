import getters from './getters';
import mutations from './mutations';
import actions from './actions';
const state = {
    materials: []
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
