let mutations = {
    GET_CUSTOMERS(state, data){
        state.customers = data;
    },
    empty_customers(state) {
        state.customers = [];
    },
    GET_LOGBOOK_RECEIVERS(state, data) {
        state.customer = data.customer;
        state.epacco = data.epacco;
    },
    LOGBOOOK_SAVED(){

    }
}
export default mutations
